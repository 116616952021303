navigation {
    background: rgba(40, 54, 24, 0.2);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
    

}

navigation a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-cream);
    font-size: 1.1rem;

}

navigation a:hover {
    background: rgba(0, 0, 0, 0.3)
}

navigation a.active {
    background: var(--color-lbrown);
    color: var(--color-dgreen);
}