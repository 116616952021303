@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}



body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-lgreen);
    color: var(--color-cream);
    line-height: 1.7;
    /* background-image: url(../src/assets/bg-texture.jpg); */
    
}

/* default styling for elements */
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;

    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

/* css variables */
:root {
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;

    --transition: all 400ms ease;

    --color-bg: #464F51;
    --color-bg-variant: #97B1A6;
    --color-primary: #000009;
    --color-primary-variant: #DEFFF2;
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);
    --color-button: #14141a;

    --color-lgreen: #606c38;
    --color-dgreen: #283618;
    --color-cream: #fefae0;
    --color-lbrown: #dda15e;
    --color-dbrown: #bc6c25;
}

/*  General Styling   */
.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 8rem;
    height: 100vh;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-cream)
}

section > h2 {
    color: var(--color-cream);
    margin-bottom: 3rem;
}

h3 {
    color: var(--color-lbrown)
}

.text-light {
    color: var(--color-lbrown);
    transition: var(--transition);
}

a {
    color: var(--color-lbrown);
}

a.hover {
    color: var(--color-cream);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-cream);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-cream);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-cream);
    color: var(--color-lgreen);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-dbrown);
    color: var(--color-cream);
}

img { 
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
}

/*   Media Queries (Medium Device)   */
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md)
    }

    section {
        margin-top: 6rem;
    }
    
}

/*   Media Queries (Small Device)   */
@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm)
    }

    section > h2{
        margin-bottom: 2rem;
    }
    
}