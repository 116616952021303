header {
    height: 110vh;
    padding-top: 4rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* Call to Action Button */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* Socials */
.personal__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;

}

.personal__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-dgreen);
}

/*  Myself (image)  */
.myself {
    background: linear-gradient(45deg, var(--color-dbrown) 0 80%, var(--color-lbrown) 30% 100%);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 3rem 3rem 3rem 3rem;
    
    padding: 2.2rem 1.5rem 1.5rem 1.5rem;
}

/*  Scroll Down  */
.scroll_down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/*   Media Queries (Medium Device)   */
@media screen and (max-width: 1024px) {
    .container {
        height: 68vh;
    }
}

/*   Media Queries (Small Device)   */
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header__socials,
    .scroll__down {
        display: none;
    }

}