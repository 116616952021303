
.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
    
}

.about__me {
    width: 100%;
    aspect-ratio: 1.3;
    border-radius: 2rem;
    background: linear-gradient(
        180deg, 
        transparent, 
        var(--color-dbrown), 
        transparent
    );
    display: flex;
    align-items: center;

}

.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
    width: 100%;
    max-width: 800px; /* Set a maximum width for responsiveness */
}

.about__me-image:hover {
    transform: rotate(0);
}

.about__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.about__card {
    background: var(--color-dgreen);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);
}

.about__card:hover {
    background: transparent;
    border-color: var(--color-cream);
    cursor: default;
}

.about__icon {
    color: var(--color-dbrown);
    font-size: 1.5rem;
}

.about__card h5 {
    font-size: 1rem;
    color: var(--color-cream);
}

.about__card small {
    font-size: 0.7rem;
    color: var(--color-lbrown);
}

.about__content p {
    margin: 2rem 0 2.6rem;
    color: var(--color-cream);
}

.about__me-content {
    display: flex;
    gap: 2rem;
    margin-top: 1rem;
}

.about__me-content small {
    font-weight: bold;
}



/*   Media Queries (Medium Device)   */
@media screen and (max-width: 1024px) {

    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about__cards  { 
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        margin-top: 2rem;
        
    }

    .about__me {
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about__content p { 
        margin: 1rem 0 1.5rem;
    }

    .about__me-content {
        display: flex;
        flex-direction: column; /* Add this line */
        gap: 0.5rem;
        margin-top: 1rem;
    }

}

/*   Media Queries (Small Device)   */
@media screen and (max-width: 600px) {

    .about__me {
        width: 85%;
        margin: 0 auto 7rem;
    }

    .about__container {
        display: flex;
        flex-direction: column;
    }


    .about__cards  { 
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .about__content {
        text-align: center;
    }

     .about__content p {
        margin: 1.5rem 0;
     }

     .about__card {
        padding: 0.5rem;
    }
}

