.portfolio__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
    max-height: 5rem;
}

.portfolio__header {
    text-align: center;
    margin-bottom: 2rem;
}

.portfolio__item {
    background: var(--color-dgreen);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    border-color: var(--color-cream);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
    opacity: 90%;
    
    
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
    
}

/*   Media Queries (Medium Device)   */
@media screen and (max-width: 1024px) {

    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }

}

/*   Media Queries (Small Device)   */
@media screen and (max-width: 600px) {

    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .portfolio__header {
        margin-top: 14rem;
    }
    
}